import styled from '@emotion/styled'
import Link from '@/components/LocalizedGatsbyLink'
import { GridContainer } from '@/components/Grid'
import { mediaMax, mediaMin } from '@/styles/mixins'

export const StyledHeader = styled.header`
  --text-color: ${({ theme, current, mode }) => current ? theme.colors.lightGreen2 : mode === 'transparent' ? theme.colors.white : theme.colors.brown};
  --header-height : 6.4rem;
  --anim-duration: ${({ theme }) => theme.durations.fast};
  --anim-delay: 0s;
  ${mediaMax.xs}{
    --anim-delay: ${p => p.mobileNavIsOpen ? '0s' : '0.1s'}
  }

  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: var(--header-height);
  background: ${({ theme, mode }) => mode === 'transparent' ? 'transparent' : theme.colors.lightVanilla3};

  transition: all var(--anim-duration) var(--anim-delay);

  svg path,svg polygon {
    transition: all var(--anim-duration) var(--anim-delay);
  }
`

export const StyledGridContainer = styled(GridContainer)`
  display: flex;
  align-items: center;
  ${mediaMax.xs}{
    justify-content: center;
    gap: 2rem;
  }
`

export const LogoContainer = styled.h1`
  width: 17rem;
  margin-right: ${({ theme }) => theme.spacing.large};

  ${mediaMax.md}{
    width: 10rem;
  }

  ${mediaMax.xs}{
    width: 17rem;
    margin: 0;
    flex-grow: 2;
  }
`

export const LogoContainerLink = styled(Link)`
  width: 17rem;
  margin-right: ${({ theme }) => theme.spacing.large};

  ${mediaMax.md}{
    width: 10rem;
  }

  ${mediaMax.xs}{
    width: 17rem;
    margin: 0;
    flex-grow: 2;
  }
`

export const LogoTitle = styled.span`
  display: none;
`

export const IconsNav = styled.div`
  display: flex;
  ${mediaMax.xs}{
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    gap: 1rem;
  }
`

export const IconsNavMobile = styled(IconsNav)`
  display: none;
  ${mediaMax.xs}{
    display: flex;
    justify-content: flex-start;
  }
`

export const NavIcon = styled.div`
  margin-left: ${({ theme }) => theme.spacing.small};
  cursor: pointer;
  ${mediaMax.xs}{
    margin: 0;
  }
`

export const NavIconDesktop = styled(NavIcon)`
  ${mediaMax.xs}{
    display: none;
  }
`

export const NavIconDesktopTemp = styled.a`
  margin-left: ${({ theme }) => theme.spacing.small};
  cursor: pointer;
  ${mediaMax.xs}{
    margin: 0;
    display: none;
  }
`

export const NavGroup = styled.div`
  display: flex;

  ${mediaMax.xs} {
    padding: 4rem 0;
    border-bottom: 2px solid ${p => p.theme.colors.black}20;
    display: flex;
    flex-direction: column;
    margin: 0 ${p => p.theme.paddingX.m};

    &:first-of-type {
      padding-top: 0;
    }

    &:last-child{
      border-bottom: none;
    }
  }
`

export const NavItemIcon = styled.span`
  margin-left: ${({ theme }) => theme.spacing.xxsmall};
  ${mediaMax.xs}{
    display: none;
  }
`

export const NavContainer = styled.div`
  flex-grow: 1;

  ${mediaMax.xs} {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transition: clip-path .2s ease;
    position: absolute;
    top: calc(100% - 1px);
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.lightVanilla3};
    color: black;
    max-height: calc(100vh - var(--header-height) + 1px);
    overflow: scroll;

    &.is-open {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      transition: clip-path .3s ease;
    }
  }
`

export const NavItemLabel = styled.span`
  position: relative;

  &:after {
    content: "";
    width: 0;
    height: 1px;
    position: absolute;
    bottom: -5px;
    left: 0;
    transition: all ease-out .3s;
  }
`

export const NavItem = styled.a`
  text-decoration: none;
  color: var(--text-color);
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.medium};
  color: ${({ theme, current, color }) => current ? theme.colors.lightGreen2 : color === 'transparent' ? theme.colors.white : theme.colors.brown};
  transition: all ease-out .3s;
  &:not(.link){
    cursor: pointer;
  }

  &.active {
    ${mediaMin.xs}{
      color: ${({ theme, current, color }) => current ? theme.colors.lightGreen2 : color === 'transparent' ? theme.colors.white : theme.colors.brown};
      ${NavItemLabel} {
        &:after {
          width: 100%;
          background-color: ${p => p.color === 'transparent' ? p.theme.colors.white : p.theme.colors.lightGreen2};
        }
      }
    }
  }

  ${mediaMax.lg}{
    margin-right: 2rem;
    font-size: 1.2rem;
  }

  ${mediaMax.xs} {
    margin-right: 0;
    font-size: 2rem;
    margin-top: 3rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`

export const DropdownMenu = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  top: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.lightVanilla2};
  z-index: 999;
`

export const MobileItems = styled.div``
export const Item = styled.div``

export const Overlay = styled.div`
  height: ${p => p.active ? '100vh' : 0};
  width: 100vw;
  background: red;
  position: fixed;
  top: 0;
  left:0;
  z-index: 100;
  background: rgba(0,0,0,0.2);
  opacity: ${p => p.active ? 1 : 0};
  transition: opacity ease .3s;
`

export const SearchWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  display: none;

  &.visible {
    display: initial;
  }
`

export const Cart = styled.div`
  position: absolute;
  width: 400px;
  top: 100%;
  right: 15px;
  background-color: ${({ theme }) => theme.colors.lightVanilla3};
  padding: 1.5rem;
  pointer-events: none;
  touch-action: none;
  opacity: 0;
  transition: opacity ease .3s;

  ${mediaMax.md} {
    width: 100%;
    right: 0;
    left: 0;
  }

  &.visible {
    pointer-events: initial;
    touch-action: initial;
    opacity: 1;
  }

  & > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .v65-widgetModalCart-status {
      display: none;
    }

    .v65-widgetModalCart-closeButton {
      display: none;
    }

    .v65-widgetModalCart-itemMessage {
      display: none !important;
    }

    .v65-widgetModalCart-dropdown {
      width: 100%;
    }

    .v65-widgetModalCart-itemSummary {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .v65-widgetModalCart-items,
      .v65-widgetModalCart-itemSummaryHeader {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.5rem;

        .v65-widgetModalCart-itemSummaryDescription,
        .v65-widgetModalCart-itemSummaryPrice,
        .v65-widgetModalCart-itemSummaryTotal,
        .v65-widgetModalCart-itemSummaryQuantity {
          font-family: ${({ theme }) => theme.fonts.sansSerif};
          color: ${({ theme }) => theme.colors.grey};
          font-size: 1.2rem;
          letter-spacing: .1rem;
          text-transform: uppercase;
          display: flex;
        }

        .v65-widgetModalCart-itemSummaryItem,
        .v65-widgetModalCart-itemSummaryTotal {
          display: none;
        }

        .v65-widgetModalCart-itemSummaryDescription {
          order: 0;
          flex: 4;
        }

        .v65-widgetModalCart-itemSummaryQuantity {
          order: 1;
          flex: 1;
          justify-content: center;
          align-items: center;
        }

        .v65-widgetModalCart-itemSummaryPrice {
          order: 2;
          flex: 1;
        }
      }

      .v65-widgetModalCart-items {
        background-color: ${({ theme }) => theme.colors.lightGrey4};

        .v65-widgetModalCart-itemSummaryItem,
        .v65-widgetModalCart-itemSummaryPrice {
          display: none;
        }

        .v65-widgetModalCart-itemSummaryTotal {
          display: flex;
          order: 2;
          flex: 1;
          color: ${({ theme }) => theme.colors.greyText};
          letter-spacing: initial;
        }

        .v65-widgetModalCart-itemSummaryQuantity {
          color: ${({ theme }) => theme.colors.greyText};
          letter-spacing: initial;
        }

        .v65-widgetModalCart-itemSummaryDescription {
          font-family: ${({ theme }) => theme.fonts.serif};
          font-weight: bold;
          color: ${({ theme }) => theme.colors.greyText};
          text-decoration: none;
          text-transform: none;
          letter-spacing: initial;
          font-size: 1.4rem;

          a {
            font-family: ${({ theme }) => theme.fonts.serif};
            font-weight: bold;
            color: ${({ theme }) => theme.colors.greyText};
            text-decoration: none;
          }
        }
      }

      .v65-widgetModalCart-itemSummarySubTotal {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.5rem;
        border-top: 1px solid ${({ theme }) => theme.colors.lightGrey2};
        margin-top: 3rem;

        .v65-widgetModalCart-itemSummarySubTotalLabel {
          flex: 5;
          font-family: ${({ theme }) => theme.fonts.serif};
          font-weight: bold;
          color: ${({ theme }) => theme.colors.greyText};
          text-decoration: none;
          text-transform: none;
          letter-spacing: initial;
          font-size: 1.4rem;
        }

        .v65-widgetModalCart-itemSummarySubTotalValue {
          flex: 1;
          font-family: ${({ theme }) => theme.fonts.sansSerif};
          color: ${({ theme }) => theme.colors.greyText};
          font-size: 1.2rem;
          text-transform: uppercase;
          display: flex;
        }
      }
    }

    .v65-widgetModalCart-itemMessageBottom {
      padding: 1.5rem;
      border-top: 1px solid ${({ theme }) => theme.colors.lightGrey2};

      a {
        font-family: ${({ theme }) => theme.fonts.sansSerif};
        color: ${({ theme }) => theme.colors.greyText};
        font-size: 1.2rem;
        display: flex;
        text-decoration: underline;
      }
    }

    .v65-widgetModalCart-BottomButtons {
      border-top: 1px solid ${({ theme }) => theme.colors.lightGrey2};
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1.5rem;
      padding-top: 1.5rem;

      .v65-widgetModalCart-checkOutButton,
      .v65-widgetModalCart-viewCartButton {
        width: 100%;

        a {
          background-color: ${({ theme }) => theme.colors.white};
          padding: 1.8rem 4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border: 1px solid ${({ theme }) => theme.colors.lightGreen};
          width: 100%;
          text-align: center;
          text-decoration: none;
          color: ${({ theme }) => theme.colors.greyText};
          white-space: nowrap;
          text-transform: uppercase;
          font-size: 1.2rem;
          font-family: ${({ theme }) => theme.fonts.sansSerif};
          font-weight: bold;
          letter-spacing: 0.1rem;
          transition: all ease-out .3s;

          &:hover {
            background-color: ${({ theme }) => theme.colors.lightGreen};
            color: ${({ theme }) => theme.colors.white};
          }
        }
      }

      .v65-widgetModalCart-checkOutButton {
        a {
          background-color: ${({ theme }) => theme.colors.lightGreen};
          color: ${({ theme }) => theme.colors.white};

          &:hover {
            background-color: ${({ theme }) => theme.colors.white};
            color: ${({ theme }) => theme.colors.greyText};
          }
        }
      }
    }
  }
`

export const UserTool = styled.div`
  position: absolute;
  width: 400px;
  top: 100%;
  right: 15px;
  background-color: ${({ theme }) => theme.colors.lightVanilla3};
  padding: 1.5rem;
  pointer-events: none;
  touch-action: none;
  opacity: 0;
  transition: opacity ease .3s;

  ${mediaMax.md} {
    width: 100%;
    right: 0;
    left: 0;
  }

  &.visible {
    pointer-events: initial;
    touch-action: initial;
    opacity: 1;
  }

  .usertools {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;

    .v65-widgetLogin-logout,
    .v65-widgetLogin-editProfile,
    .v65-widgetLogin-yourAccount,
    .v65-widgetLogin-login {
      width: 100%;

      a {
        background-color: ${({ theme }) => theme.colors.white};
        padding: 1.8rem 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 1px solid ${({ theme }) => theme.colors.lightGreen};
        width: 100%;
        text-align: center;
        text-decoration: none;
        color: ${({ theme }) => theme.colors.greyText};
        white-space: nowrap;
        text-transform: uppercase;
        font-size: 1.2rem;
        font-family: ${({ theme }) => theme.fonts.sansSerif};
        font-weight: bold;
        letter-spacing: 0.1rem;
        transition: all ease-out .3s;

        &:hover {
          background-color: ${({ theme }) => theme.colors.lightGreen};
          color: ${({ theme }) => theme.colors.white};
        }
      }
    }

    .v65-widgetLogin-editProfile,
    .v65-widgetLogin-login {
      a {
        background-color: ${({ theme }) => theme.colors.lightGreen};
        color: ${({ theme }) => theme.colors.white};

        &:hover {
          background-color: ${({ theme }) => theme.colors.white};
          color: ${({ theme }) => theme.colors.greyText};
        }
      }
    }
  }
`
